import * as serviceType from './constant';
import { getServices } from '../api/api.services';

export const getAllServices = () => async (dispatch, getState) => {
    const response = await getServices();

    if (response.ok) {
        return dispatch({
            type: serviceType.GET_SERVICES,
            payload: response.data.data
        });
    }
};
