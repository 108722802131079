import * as actionTypes from './constant';

const initialState = {
    services: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_SERVICES:
            return {
                ...state,
                services: action.payload
            };

        default:
            return state;
    }
}
